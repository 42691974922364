<template>
  <div id="FamilyIndex">
    <!-- 查询 -->
    <van-dropdown-menu active-color="#23AB72">
      <van-dropdown-item v-model="query.yearNo" :options="options" @change="changeYear" />
    </van-dropdown-menu>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad" style="margin-bottom:64px">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{row.yearNo +'年'}}</span></span>
          </template>
          <template #label>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="cash-on-deliver" /> 学号: {{row.stuNo }}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="refund-o" /> 姓名: {{ row.stuName }}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="underway-o" /> 申请时间: {{parseTime(row.createTime, "{y}-{m}-{d}")}}
            </div>
          </template>
          <template #right-icon>
            <span v-if="row.approvalStatus ==='0'" class="audit info-color b-color-info">待提交</span>
            <span v-else-if="statustag(row.approvalStatus,'success')" class="audit success-color b-color-success">审核通过</span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="audit info-color b-color-info">待审核</span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="audit error-color b-color-error">审核不通过</span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="audit warning-color b-color-warning">审核驳回</span>
            <span class="table-detail-btn detail-pos" @click="toDetail(row)" style="color:#B2B2B2"> 详情
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <span v-if="statustag(row.approvalStatus,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
          </template>
          <template #right-icon>
            <van-button class="table-del-btn" :disabled="row.approvalStatus === 9" @click="toDel(row)" :loading="row.delLoading" plain round size="small" type="warning">删除</van-button>
            <van-button class="table-edit-btn" :disabled="row.approvalStatus === 9" @click="toEdit(row)" plain round size="small" type="primary">编辑</van-button>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <!-- 提交 -->
    <div class="submit-button shadow-style">
      <van-button round block type="primary" @click="doAdd">新 增</van-button>
    </div>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import crudReplacement from '@/api/modules/daily/stuCardReplacement'

export default {
  name: "StuPayReport",
  mixins: [initData],
  dicts: ["xj_shzt", "xj_nj"],
  data() {
    return {
      options: [],
      loading: false
    }
  },
  watch: {
    "dict.xj_nj": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0) {
          this.options = this.convertData(newval);
        }
      },
    },
  },
  created() {

  },
  methods: {
    beforeInit() {
      this.url = "/api/stuCard/replacement";
      this.sort = ['approvalStatus,asc', 'createTime,desc', 'yearNo,desc'];
      this.query.yearNo = new Date().getFullYear().toString();
      return true;
    },
    changeYear(val) {
      this.$nextTick((e) => {
        this.query.yearNo = val;
      });
      this.toQuery();
    },
    convertData(dict) {
      let options = [];
      dict.forEach((item) => {
        let data = { text: item.label + "年", value: item.value };
        options.push(data);
      });
      if (options) {
        this.query.yearNo = new Date().getFullYear().toString();
      }
      return options;
    },
    doAdd() {
      this.$router.push({
        path: "/js/stu/card/form",
      });
    },
    toEdit(data) {
      this.$router.push({
        path: "/js/stu/card/form",
        query: { id: data.id },
      });
    },
    toDetail(data) {
      this.$router.push({
        path: "/js/stu/card/detail",
        query: { id: data.id, status: data.approvalStatus },
      });
    },
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          this.$set(data, "delLoading", true);
          crudReplacement.del([data.id])
            .then((res) => {
              this.$notify({
                type: "success",
                message: "操作成功",
                duration: 2000,
              });
              this.init();
              this.$set(data, "delLoading", false);
            })
            .catch((e) => {
              this.$set(data, "delLoading", false);
            });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.detail-pos {
  position: absolute;
  top: 50%;
  right: 15px;
}
</style>